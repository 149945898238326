<template>
  <div>
    <van-list>
      <van-cell-group>
        <van-cell title="就诊人" :value="Patient" @click="onqiehuan">
          <van-icon
            slot="right-icon"
            class="iconfont"
            class-prefix="icon"
            name="qiehuan"
            color="#57c4b7"
            style="line-height: inherit; margin-left: 4%"
          />
        </van-cell>
        <!-- <van-cell title="就诊卡" :value="CardNo" /> -->
        <van-cell title="预约科室" :value="Depname" />
        <van-cell title="预约医生" :value="docname" />
        <van-cell title="诊疗费">
          <template slot="default">
            <span style="color: #57c4b7">{{ Fee }}</span
            >元
          </template>
        </van-cell>
        <van-cell>
          <div>
            <span>就诊时间</span>
            <span style="float: right; font-size: 12px">{{ Dates }}</span>
          </div>
        </van-cell>
      </van-cell-group>
      <van-button
        type="primary"
        :disabled="disabled"
        style="margin: 4% 5% auto 5%; width: 90%; height: 40%"
        color="#57c4b7"
        round
        size="large"
        @click="onquery()"
        >确认挂号</van-button
      >
    </van-list>
    <div
      style="
        font-size: 12px;
        color: #d3d3d3;
        margin: 1% 40%;
        width: auto;
        height: 40px;
      "
    >
      <span>&copy;拓实银医</span>
    </div>
  </div>
</template>

<script>
import "@/assets/iconfont/switch/iconfont.css";
import ajax from "../../lib/ajax";
import appconst from "../../lib/appconst";
import { Toast, Dialog } from "vant";
import store from "../../store";
const OperatorId = "100002";
let userName = "";
let cardNo = ""; //声明就诊卡号
let depname = ""; //声明科室名称
let dorname = ""; //声明医生姓名
let totalFee = ""; //声明挂号费
// let dates = "";
// let reslist = [];
let orderid = "";
// let seetime = "";

export default {
  data() {
    return {
      Patient: "",
      CardNo: "",
      docname: "",
      Depname: "",
      Doctor: "",
      Fee: "",
      Dates: "",
      orderId: "",
      title: "",
      disabled: false,
      wxPayInfo: {
        timestamp: "",
        nonceStr: "",
        package: "",
        signType: "",
        paySign: "",
      },
      wxconfig: {
        debug: false,
        // appId: "wxab583b5b776e236b",
        appId: "",
        timestamp: "",
        nonceStr: "",
        signature: "",
        jsApiList: ["chooseWXPay", "hideOptionMenu"],
      },
      loading: false,
      finished: false,
    };
  },
  created() {
    userName = store.getters["store/userName"];
    this.Patient = userName;
    cardNo = store.getters["store/cardNo"]; //声明就诊卡号
    this.CardNo = cardNo;
    depname = store.getters["store/depname"]; //声明科室名称
    this.Depname = depname;
    dorname = store.getters["store/dorname"]; //声明医生姓名
    this.Doctor = dorname;
    let name = dorname.split("|");
    this.docname = name[0];

    totalFee = store.getters["store/totalFee"]; //声明挂号费
    this.Fee = totalFee;
    // var seeTime = store.getters["store/seeTime"]; //声明看诊时间
    var beginTime = store.getters["store/beginTime"]; //声明看诊开始时间
    var endTime = store.getters["store/endTime"]; //声明看诊结束时间
    // dates =
    //   seeTime.substring(4, 6) +
    //   "/" +
    //   seeTime.substring(6, 8) +
    //   "/" +
    //   beginTime +
    //   "-" +
    //   endTime;
    this.Dates = beginTime;
    // console.log("看诊时间" + seeTime);
    console.log("看诊开始时间" + beginTime);
    console.log("看诊结束时间" + endTime);
    // seetime = seeTime;
    this.onload();
  },
  methods: {
    async onload() {
      console.log("就诊人" + this.Patient);
      console.log("就诊卡号" + this.CardNo);
      console.log("科室名称" + this.Depname);
      console.log("医生姓名" + this.Doctor);
      console.log("挂号费" + this.Fee);
      // alert(regLevelName);
      let me = this;
      if (me.isWeixin()) {
        me.initWxConfig();
        wx.hideOptionMenu(); // 禁用分享
        // TODO：加载订单信息用于显示
      } else {
        Toast.fail({ message: "请在微信中打开此页面", duration: 0 });
      }
    },
    isWeixin() {
      return /MicroMessenger/gi.test(navigator.userAgent);
    },
    initWxConfig() {
      // 初始化微信JS功能配置
      let me = this;
      return new Promise((resolve, reject) => {
        me.getWxjsSign(appconst.wechatAppId)
          .then((x) => {
            // alert(appconst.wechatAppId);
            var config = {
              appId: appconst.wechatAppId,
              timestamp: x["timestamp"],
              nonceStr: x["noncestr"],
              signature: x["sign"],
              ticket: x["ticket"],
            };
            me.wxconfig = Object.assign(me.wxconfig, config);
            wx.ready((x) => {
              if (x) {
                //
              }
              resolve(me.wxconfig);
            });
            wx.error((err) => {
              if (err) {
                //
              }
              reject(x);
            });
            wx.config(me.wxconfig);
            // alert(wx);
          })
          .catch((x) => {
            reject(x);
          });
      });
    },
    // 获取微信JS签名
    getWxjsSign() {
      let url = encodeURIComponent(location.href);
      var center = `${appconst.thirdApiCenterBaseUrl}Api/WxMpOauth/JsSign`;
      return new Promise((resolve, reject) => {
        ajax
          .get(center, {
            params: {
              appid: appconst.wechatAppId,
              url: url,
            },
          })
          .then((x) => {
            resolve(x.data.result);
            console.log("测试" + x.data.result);
          })
          .catch((x) => {
            reject(x);
            console.log(x);
          });
      });
    },

    // 切换就诊卡
    onqiehuan() {
      this.$router.replace({
        path: "/CardL",
      });
    },
    // 提交挂号并完成挂号费支付
    async onquery() {
      this.disabled = true;
      Toast.loading({
        message: "正在处理中，请稍后...",
        forbidClick: true,
        loadingType: "spinner",
      });
      let me = this;
      // let depid = store.getters["store/depid"]; //声明科室id
      var regLevel = store.getters["store/regLevel"]; //声明挂号级别
      // var scheduleid = store.getters["store/scheduleid"]; //声明号源
      var patientid = store.getters["store/patientid"]; //声明病历号
      let seeTime = store.getters["store/seeTime"]; //声明看诊时间
      var schemaNO = store.getters["store/schemaNO"];
      var idcard = store.getters["store/idcard"];
      var phone = store.getters["store/phone"];
      var regLevelName = store.getters["store/regLevelName"]; //声明挂号名称
      console.log(regLevelName);
      var openId = store.getters["wechat/openid"];
      // var seeNo = store.getters["store/seeNo"];
      var deptCode = store.getters["store/dorid"];
      var doctCode = store.getters["store/doctCode"];
      console.log(doctCode);
      var noonId = store.getters["store/noonId"];
      var beginTime = store.getters["store/beginTime"]; //声明看诊开始时间
      var endTime = store.getters["store/endTime"]; //声明看诊结束时间
      // var schemaId = store.getters["store/schemaId"]; //声明排班流水号
      console.log(patientid);
      ajax
        .post("/Api/PayService/NewOrder", {
          userID: patientid,
          userName: this.Patient,
          idCard: idcard,
          phone: phone,
          title: "挂号",
          content:
            this.Depname +
            "|" +
            this.Doctor +
            "|" +
            regLevelName +
            "|" +
            this.Fee,
          // content:depname+'|'+dorname+'|'+regLevel+'|'+0.01,
          description: "微信公众号支付" + "|" + this.Patient + "|" + this.Fee,
          // description:"微信公众号支付"+'|'+userName+'|'+0.01,
          hasCard: false,
          // cost:0.01,
          cost: this.Fee,
          machineName: "wechat",
          wxOpenID: openId,
        })
        .then(async (res) => {
          orderid = res.data.result;
          // alert(orderid);
          if (orderid > 0) {
            // await ajax
            //   .post("Api/RegistrationService/IsRepeatRegist", {
            //     patientID: patientid,
            //     orderId: orderid,
            //     deptId: depid,
            //     // regLevelId: regLevel,
            //     regDate: seeTime,
            //     patientName: this.Patient,
            //     idCardNo: idcard,
            //     totFee: this.Fee,
            //     noonId: noonId,
            //     beginTime: beginTime,
            //     endTime: endTime,
            //     payWay: 3,
            //     operatorId: "999986",
            //     machineName: "wechat",
            //     mCardNo: idcard,
            //     // schemaNO: schemaNO,
            //     deptCode: depid,
            //     // doctCode: "PZ9067",
            //     reglevlCode: regLevel,
            //     reglevlName: regLevelName,
            //     // bookCode: "string",
            //     seeNO: seeNo,
            //     listItem: {
            //       payTypeID: 3,
            //       payModeName: "微信",
            //       amount: this.Fee,
            //     },
            //   })
            //   .then(async (res) => {
            //     reslist = res.data.result;
            //     console.log(reslist);
            //     if (reslist.success) {
            // 将要进行支付前 拟定订单
            // let regno = reslist.regInfo.regNo;
            await ajax
              .post("/Api/RegistrationService/LockNo", {
                schemaId: schemaNO,
                patientID: patientid,
              })
              .then(async (res) => {
                var seeNo = res.data.result.value;
                var success = res.data.result.success;
                if (success === false) {
                  Toast("锁定号源失败"+ res.data.result.message);
                  return;
                }
                await me.getWxPayInfo();
                await ajax
                  .post("/Api/WechatAgentService/AgentRegistPaid", {
                    patientID: patientid,
                    openID: openId,
                    idCardNo: idcard,
                    patientName: this.Patient,
                    noonId: noonId,
                    regDate: seeTime,
                    schemaNO: schemaNO,
                    deptCode: deptCode,
                    doctCode: doctCode,
                    beginTime: beginTime,
                    endTime: endTime,
                    operatorId: OperatorId,
                    totFee: this.Fee,
                    machineName: "wechat",
                    orderId: orderid,
                    termialType: null,
                    payWay: 3,
                    seeNo: seeNo,
                    regLevel: regLevelName,
                    reglevlCode: regLevel,
                    userName: this.Patient,
                    hisFromDept: depname,
                    hisFromDoctor: dorname,
                    hisFromCreateTime: seeTime,
                  })
                  .then(async (res) => {
                    if (res.data.result === 0 || res.data.result === 2) {
                      Toast.clear();
                      wx.chooseWXPay({
                        // appId: me.wxPayInfo.appId,
                        timestamp: me.wxPayInfo.timestamp,
                        nonceStr: me.wxPayInfo.nonceStr,
                        package: me.wxPayInfo.package,
                        signType: me.wxPayInfo.signType,
                        paySign: me.wxPayInfo.paySign,
                        success: async function (res) {
                          // Toast(orderid);
                          if (res.errMsg === "chooseWXPay:ok") {
                            //更新订单
                            // store.commit("store/setOrderId",orderid);
                            // sessionStorage.setItem("orderid", orderid);
                            // sessionStorage.setItem("openId", openId);
                            // sessionStorage.setItem("userName", userName);
                            // sessionStorage.setItem("depname", depname);
                            // sessionStorage.setItem("dorname", dorname);
                            // sessionStorage.setItem("regNo", reslist.regInfo.regNo);
                            // sessionStorage.setItem("seeTime", seeTime);

                            Toast("挂号成功，请按照预约时间进行就诊");
                            window.location.href = "/IWait";
                          }
                          //window.location.href = "/WechatPay/Result?returnurl="+encodeURIComponent("@ViewData["returnurl"]")+"&oid=" + '@ViewData["oid"]' + "&state=" + /\w+$/.exec(res.errMsg)[0];
                        },
                        fail: async function (res) {
                          // Toast.clear();
                          if (res) {
                            // let regno = reslist.regInfo.regNo;
                            // console.log(regno);
                            // // alert(regno);
                            // await ajax
                            //   .post(
                            //     "/Api/RegistrationService/CancelRegist",
                            //     {
                            //       regNo: regno,
                            //       orderId: orderid,
                            //       machineName: "wechat",
                            //     }
                            //   )
                            //   .then(async (res) => {
                            //     console.log(res.data.result);
                            //     let suc = res.data.result;
                            // if (suc.success) {
                            await ajax
                              .post("/Api/RegistrationService/UnLockNo", {
                                schemaId: schemaNO,
                                seeNo: seeNo,
                                patientID: patientid,
                              })
                              .then(async () => {
                                this.disabled = false;
                                Toast("您已取消挂号");
                              })
                              .catch((err) => {
                                console.log(err);
                              });
                            // }
                            // })
                            // .catch((err) => {
                            //   Toast("出现未知错误" + err);
                            // });
                          }
                          console.log("fail");
                        },
                        cancel: async function (res) {
                          // Toast.clear();
                          if (res) {
                            //   let regno = reslist.regInfo.regNo;
                            //   console.log(regno);
                            //   // alert(regno);
                            //   await ajax
                            //     .post(
                            //       "/Api/RegistrationService/CancelRegist",
                            //       {
                            //         regNo: regno,
                            //         orderId: orderid,
                            //         machineName: "wechat",
                            //       }
                            //     )
                            //     .then(async (res) => {
                            //       console.log(res.data.result);
                            //       // alert(JSON.stringify(res.data.result));
                            //       let suc = res.data.result;
                            //       if (suc.success) {
                            await ajax
                              .post("/Api/RegistrationService/UnLockNo", {
                                schemaId: schemaNO,
                                seeNo: seeNo,
                                patientID: patientid,
                              })
                              .then(async () => {
                                this.disabled = false;
                                Toast("您已取消挂号");
                              })
                              .catch((err) => {
                                console.log(err);
                              });
                            // }
                            // })
                            // .catch((err) => {
                            //   // alert(err)
                            //   Toast("出现未知错误" + err);
                            // });
                          }
                          console.log("cancel");
                        },
                      });
                    } else if (res.data.result === 1) {
                      Toast.clear();
                      Toast("您已挂号成功，请按照预约时间进行就诊");
                    } else {
                      Toast.clear();
                      Toast("挂号失败，系统异常" + res.data.result);
                    }
                  })
                  .catch(() => {
                    Toast.clear();
                    // Toast("出现未知错误：" + err);
                  });
              })
              .catch((err) => {
                Toast("锁定号源失败" + err.message);
                console.log(err);
              });
            //   } else {
            //     Toast("错误：" + reslist.errorMsg);
            //   }
            // })
            // .catch((err) => {
            //   Toast("提交订单失败" + err.message);
            //   console.log(err);
            // });
          } else {
            Toast("创建订单失败");
          }
          // console.log(orderid);
        })
        .catch((err) => {
          // 更新orderid
          Toast("创建订单失败" + err.message);
        });
    },

    async getWxPayInfo() {
      let me = this;
      //const query = $route;  // 从Url上获取订单Id
      //me.orderId = query.orderId;
      me.orderId = orderid;
      // console.log("123456");
      console.log("orderid是" + me.orderId);
      await ajax
        .post("/Api/PayService/CreatePay", {
          orderId: orderid,
          payWay: "微信公众号",
          operatorId: OperatorId,
        })
        .then((rep) => {
          let res = rep.data.result;
          console.log("res是：" + JSON.stringify(res));
          if (res.result === 1) {
            let dat = JSON.parse(res.value);
            // alert("dat是"+ JSON.stringify(dat));
            // console.log(JSON.stringify(dat));
            // me.wxPayInfo.appId = dat.AppId;
            me.wxPayInfo.timestamp = dat.TimeStamp;
            me.wxPayInfo.nonceStr = dat.NonceStr;
            me.wxPayInfo.package = dat.Package;
            me.wxPayInfo.signType = dat.SignType;
            me.wxPayInfo.paySign = dat.PaySign;
            // console.log("公众号Id：" + me.wxPayInfo.appId);
            console.log("时间戳" + me.wxPayInfo.timestamp);
            console.log("随机字符串" + me.wxPayInfo.nonceStr);
            console.log("订单详情拓展字符串" + me.wxPayInfo.package);
            console.log("签名方式" + me.wxPayInfo.signType);
            console.log("签名" + me.wxPayInfo.paySign);
          } else {
            Dialog.alert({
              message: "签名验证失败",
            }).then(() => {
              //
            });
          }
        })
        .catch((err) => {
          Toast(err);
        });
    },
  },
};
</script>

<style></style>
